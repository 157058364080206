import React, { ComponentType } from "react";
import { Navigate } from "react-router-dom";

import { ROUTE } from "../constants/paths";
import { getToken } from "../utils/common";

const notAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const isAuthenticated = getToken();

  const AuthenticatedComponent: React.FC<P> = (props) => {
    if (!isAuthenticated) {
      return <WrappedComponent {...props} />;
    }
    return <Navigate to={ROUTE.HOME} />;
  };

  return AuthenticatedComponent;
};

export default notAuth;
