import { LinearProgress } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserProvider } from "context/UserContext";
import TreatmentTagList from "pages/Tags/EditLinkedTreatment";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { ROUTE } from "./constants/paths";
import notAuth from "./hocs/notAuthentication";
import withAuth from "./hocs/withAuthentication";
import CustomerMypage from "./pages/CustomerPages/Mypage";
import CustomerMyProposal from "./pages/CustomerPages/Mypage/MyProposal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C181F3"
    }
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false
    }
  }
});

//--- Pages lazy
const LoginPage = notAuth(React.lazy(() => import("./pages/Login")));
const ForgotPasswordPage = notAuth(
  React.lazy(() => import("./pages/ForgotPassword"))
);
const SendMailPage = notAuth(
  React.lazy(() => import("./pages/ForgotPassword/SendMail"))
);
const MailIsSentPage = notAuth(
  React.lazy(() => import("./pages/ForgotPassword/SendMailSuccess"))
);
const NewPasswordPage = notAuth(
  React.lazy(() => import("./pages/ForgotPassword/EnterNewPassword"))
);
const UpdatePasswordSuccessPage = notAuth(
  React.lazy(() => import("./pages/ForgotPassword/UpdatePasswordSuccess"))
);
const CustomerEnterInformationPage = withAuth(
  React.lazy(() => import("./pages/CustomerEnterInformation"))
);
const CustomerEnterSuccessPage = withAuth(
  React.lazy(() => import("./pages/CustomerEnterSuccess"))
);

const CustomerSurveyPage = withAuth(
  React.lazy(() => import("./pages/CustomerSurvey"))
);
const CustomerFormPage = React.lazy(() => import("./pages/CustomerForm"));

const SalonPage = withAuth(React.lazy(() => import("./pages/Salon")));

const CustomerEchartContainerPage = React.lazy(
  () => import("./pages/CustomerEchart/container")
);
const CustomerEchartPage = withAuth(
  React.lazy(() => import("./pages/CustomerEchart"))
);
const TakePhotoPage = withAuth(
  React.lazy(() => import("./pages/CustomerEchart/TakePhoto"))
);
const UploadPhotoPage = withAuth(
  React.lazy(() => import("./pages/CustomerEchart/UploadPhoto"))
);
const ConfirmPhotoPage = withAuth(
  React.lazy(() => import("./pages/CustomerEchart/ConfirmPhoto"))
);
const BasicInfoPage = React.lazy(
  () => import("./pages/CustomerEchart/BasicInfo")
);
const FormInquiryAnswerPage = React.lazy(
  () => import("./pages/CustomerEchart/FormInquiryAnswer")
);

const FaceAnalysisPage = withAuth(
  React.lazy(() => import("./pages/FaceAnalysis"))
);

const ProposalPage = withAuth(React.lazy(() => import("./pages/Proposal")));
const SalonProposalPage = withAuth(
  React.lazy(() => import("./pages/Proposal/Salon"))
);
const ClinicProposalPage = withAuth(
  React.lazy(() => import("./pages/Proposal/Clinic"))
);

const FaceComparisonPage = withAuth(
  React.lazy(() => import("./pages/FaceComparison"))
);

const MyPage = React.lazy(() => import("./pages/MyPage/Index"));
const Profile = React.lazy(() => import("./pages/Profile/Index"));
const MenuCategoryPage = React.lazy(() => import("./pages/MenuCategory/Index"));
const MenuCategoryEdit = React.lazy(() => import("./pages/MenuCategory/Edit"));
const Menu = React.lazy(() => import("./pages/Menu/Index"));
const MenuTagList = withAuth(React.lazy(() => import("./pages/Menu/Tag")));

const Question = React.lazy(() => import("./pages/Question/Index"));
const QuestionThemeEditPage = React.lazy(() => import("pages/Question/Edit"));
const QuestionProductEditPage = React.lazy(
  () => import("pages/QuestionProduct/Edit")
);
const QuestionProductAddPage = React.lazy(
  () => import("pages/QuestionProduct/Add")
);
const MenuAdd = React.lazy(() => import("./pages/Menu/Add"));
const MenuEdit = React.lazy(() => import("./pages/Menu/Edit"));
const TreatmentExample = React.lazy(
  () => import("./pages/TreatmentExample/Index")
);
const TreatmentExampleEdit = React.lazy(
  () => import("./pages/TreatmentExample/Edit")
);
const TreatmentExampleAdd = React.lazy(
  () => import("./pages/TreatmentExample/Add")
);
const TagList = React.lazy(() => import("./pages/Tags/List"));
const TagCreate = React.lazy(() => import("./pages/Tags/Create"));
const TagEdit = React.lazy(() => import("./pages/Tags/Edit"));
const TagEditLinkedMenu = React.lazy(
  () => import("./pages/Tags/EditLinkedMenu")
);
const Branch = React.lazy(() => import("./pages/Branch/Index"));
const BranchEdit = React.lazy(() => import("./pages/Branch/Edit"));
const BranchAdd = React.lazy(() => import("./pages/Branch/Add"));
const User = React.lazy(() => import("./pages/User/Index"));
const UserEdit = React.lazy(() => import("./pages/User/Edit"));
const UserAdd = React.lazy(() => import("./pages/User/Add"));
const HelpCenter = React.lazy(() => import("./pages/HelpCenter"));
const CustomerList = React.lazy(() => import("./pages/Customer/CustomerList"));
const AddCustomer = React.lazy(() => import("./pages/Customer/AddCustomer"));
const CustomerInfo = React.lazy(() => import("./pages/Customer/CustomerInfo"));
const PreProposal = React.lazy(
  () => import("./pages/Customer/CustomerInfo/PreProposal/Index")
);
const Diagnosis = React.lazy(() => import("./pages/DiagnosisType"));
const TypeMatching = React.lazy(() => import("./pages/TypeMatching"));
const FaceScanPages = React.lazy(() => import("./pages/FaceScanPages"));
const AdminInformation = React.lazy(() => import("./pages/AdminInformation"));
const Plan = React.lazy(() => import("./pages/Plan"));
const BrandManagement = React.lazy(() => import("./pages/BrandManagement"));
const BranchAnalysis = React.lazy(() => import("./pages/BranchAnalysis"));
const CustomerQuestions = React.lazy(
  () => import("./pages/Customer/CustomerQuestions")
);
const CustomerQuestionStarter = React.lazy(
  () => import("./pages/CustomerPages/Question/Starter")
);

const CustomerQuestionSecond = React.lazy(
  () => import("./pages/CustomerPages/Question/Second")
);
const CustomerQuestionProductShow = React.lazy(
  () => import("./pages/CustomerPages/Question/Product/Show")
);
const CustomerPhoto = React.lazy(
  () => import("./pages/Customer/CustomerPhoto")
);
const CustomerFaceScan = React.lazy(
  () => import("./pages/CustomerPages/CustomerFaceScan")
);
const CreateReservation = React.lazy(
  () => import("./pages/Customer/components/ReservationForm/CreateReservation")
);
const UpdateReservation = React.lazy(
  () => import("./pages/Customer/components/ReservationForm/UpdateReservation")
);

function Router() {
  return (
    <>
      <Helmet></Helmet>

      <ToastContainer className="custom-react-toastify" />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Suspense fallback={<LinearProgress />}>
            <UserProvider>
              <ThemeProvider theme={theme}>
                <Routes>
                  <Route path={ROUTE.LOGIN} element={<LoginPage />} />

                  <Route
                    path={ROUTE.FORGOT_PASSWORD.INDEX}
                    element={<ForgotPasswordPage />}
                  >
                    <Route index element={<SendMailPage />} />
                    <Route
                      path={ROUTE.FORGOT_PASSWORD.MAIL_IS_SENT}
                      element={<MailIsSentPage />}
                    />
                    <Route
                      path={ROUTE.FORGOT_PASSWORD.UPDATE_PASSWORD}
                      element={<NewPasswordPage />}
                    />
                    <Route
                      path={ROUTE.FORGOT_PASSWORD.UPDATE_PASSWORD_SUCCESS}
                      element={<UpdatePasswordSuccessPage />}
                    />
                  </Route>
                  <Route
                    path={ROUTE.CUSTOMER_ENTER_INFORMATION}
                    element={<CustomerEnterInformationPage />}
                  />
                  <Route
                    path={ROUTE.CUSTOMER_ENTER_SUCCESS}
                    element={<CustomerEnterSuccessPage />}
                  />

                  <Route path={ROUTE.SALON.INDEX} element={<SalonPage />} />

                  <Route
                    path={ROUTE.CUSTOMER_SURVEY}
                    element={<CustomerSurveyPage />}
                  />
                  <Route
                    path={ROUTE.CUSTOMER_FORM}
                    element={<CustomerFormPage />}
                  />

                  <Route
                    path={ROUTE.MENU_CATEGORY.INDEX}
                    element={<MenuCategoryPage />}
                  />
                  <Route
                    path={`${ROUTE.MENU_CATEGORY.INDEX}/${ROUTE.MENU_CATEGORY.EDIT}`}
                    element={<MenuCategoryEdit />}
                  />
                  <Route
                    path={`${ROUTE.MENU_CATEGORY.INDEX}/:categoryId/${ROUTE.MENU.INDEX}`}
                    element={<Menu />}
                  />
                  <Route
                    path={`${ROUTE.MENU_CATEGORY.INDEX}/:categoryId/${ROUTE.MENU.ADD}`}
                    element={<MenuAdd />}
                  />
                  <Route
                    path={`${ROUTE.MENU_CATEGORY.INDEX}/:categoryId/${ROUTE.MENU.ADD}/edit-tag`}
                    element={<MenuTagList />}
                  />
                  <Route
                    path={`${ROUTE.MENU_CATEGORY.INDEX}/:categoryId/${ROUTE.MENU.EDIT}/:menuId`}
                    element={<MenuEdit />}
                  />

                  <Route
                    path={ROUTE.CUSTOMER_ECHART.INDEX + "/:id"}
                    element={<CustomerEchartContainerPage />}
                  >
                    <Route index element={<CustomerEchartPage />} />
                    <Route
                      path={`${ROUTE.CUSTOMER_ECHART.TAKE_PHOTO}/:customerEchartId`}
                      element={<TakePhotoPage />}
                    />
                    <Route
                      path={`${ROUTE.CUSTOMER_ECHART.UPLOAD_PHOTO}/:customerEchartId`}
                      element={<UploadPhotoPage />}
                    />
                    <Route
                      path={`${ROUTE.CUSTOMER_ECHART.CONFIRM_PHOTO}/:customerEchartId`}
                      element={<ConfirmPhotoPage />}
                    />
                  </Route>

                  <Route
                    path={ROUTE.CUSTOMER_BASIC_INFO + "/:id"}
                    element={<BasicInfoPage />}
                  />
                  <Route
                    path={
                      ROUTE.CUSTOMER_ECHART.INDEX +
                      "/:customerEchartId/inquiry-answer/:companyId"
                    }
                    element={<FormInquiryAnswerPage />}
                  />

                  <Route
                    path={`${ROUTE.FACE_ANALYSIS}/:id`}
                    element={<FaceAnalysisPage />}
                  />

                  <Route path={ROUTE.PROPOSAL.INDEX} element={<ProposalPage />}>
                    <Route index element={<SalonProposalPage />} />
                    <Route
                      path={`${ROUTE.PROPOSAL.INDEX}/${ROUTE.PROPOSAL.CLINIC}`}
                      element={<ClinicProposalPage />}
                    />
                  </Route>

                  <Route
                    path={`${ROUTE.FACE_COMPARISON.INDEX}/:id`}
                    element={<FaceComparisonPage />}
                  />

                  <Route path={`${ROUTE.MYPAGE.INDEX}`} element={<MyPage />} />
                  <Route
                    path={`${ROUTE.MYPAGE.PROFILE}`}
                    element={<Profile />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.QUESTION.INDEX}`}
                    element={<Question />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.QUESTION.EDIT}`}
                    element={<QuestionThemeEditPage />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.QUESTION_PRODUCT.EDIT}`}
                    element={<QuestionProductEditPage />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.QUESTION_PRODUCT.ADD}`}
                    element={<QuestionProductAddPage />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.TREATMENT_EXAMPLE}`}
                    element={<TreatmentExample />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.TREATMENT_EXAMPLE_EDIT}`}
                    element={<TreatmentExampleEdit />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.TREATMENT_EXAMPLE_ADD}`}
                    element={<TreatmentExampleAdd />}
                  />

                  <Route
                    path={`${ROUTE.MYPAGE.TREATMENT_EXAMPLE}/:treatment/edit/edit-tag`}
                    element={<MenuTagList />}
                  />

                  <Route
                    path={`${ROUTE.MYPAGE.TREATMENT_EXAMPLE}/add/edit-tag`}
                    element={<MenuTagList />}
                  />

                  <Route
                    path={`${ROUTE.CUSTOMER.CUSTOMER_INFO}/edit-tag`}
                    element={<MenuTagList />}
                  />

                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.TAGS}`}
                    element={<TagList />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.TAGS}/:tagCategoryId/create`}
                    element={<TagCreate />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.TAG_PRODUCTS}/:tagId/edit`}
                    element={<TagEdit />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.TAG_PRODUCTS}/:tagId/edit-menu`}
                    element={<TagEditLinkedMenu />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.TAG_PRODUCTS}/:tagId/edit-treatment`}
                    element={<TreatmentTagList />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.DIAGNOSIS_TYPE}`}
                    element={<Diagnosis />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.TYPE_MATCHING}`}
                    element={<TypeMatching />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.FACE_SCAN}`}
                    element={<FaceScanPages />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.ADMIN_INFORMATION}`}
                    element={<AdminInformation />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.PLAN}`}
                    element={<Plan />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.BRAND_MANAGEMENT}`}
                    element={<BrandManagement />}
                  />
                  <Route path={`${ROUTE.MYPAGE.BRANCH}`} element={<Branch />} />
                  <Route
                    path={`${ROUTE.MYPAGE.BRANCH_EDIT}`}
                    element={<BranchEdit />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.BRANCH_ADD}`}
                    element={<BranchAdd />}
                  />
                  <Route path={`${ROUTE.MYPAGE.USER}`} element={<User />} />
                  <Route
                    path={`${ROUTE.MYPAGE.USER_EDIT}`}
                    element={<UserEdit />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.USER_ADD}`}
                    element={<UserAdd />}
                  />
                  <Route
                    path={`${ROUTE.MYPAGE.INDEX}/${ROUTE.MYPAGE.HELP_CENTER}`}
                    element={<HelpCenter />}
                  />

                  <Route
                    path={`${ROUTE.ANALYSIS.INDEX}`}
                    element={<BranchAnalysis />}
                  />
                  <Route
                    path={`${ROUTE.CUSTOMER.INDEX}`}
                    element={<CustomerList />}
                  />
                  <Route
                    path={`${ROUTE.CUSTOMER.ADD}`}
                    element={<AddCustomer />}
                  />
                  <Route
                    path={`${ROUTE.CUSTOMER.CUSTOMER_INFO}`}
                    element={<CustomerInfo />}
                  />
                  <Route
                    path={`${ROUTE.CUSTOMER.RESERVATION_CREATE}`}
                    element={<CreateReservation />}
                  />
                  <Route
                    path={`${ROUTE.CUSTOMER.RESERVATION_UPDATE}`}
                    element={<UpdateReservation />}
                  />

                  <Route
                    path={`${ROUTE.CUSTOMER.PRE_PROPOSAL}`}
                    element={<PreProposal />}
                  />
                  <Route
                    path={ROUTE.CUSTOMER.CUSTOMER_QUESTIONS}
                    element={<CustomerQuestions />}
                  />
                  <Route
                    path={ROUTE.CUSTOMER.CUSTOMER_BEFORE_AFTER_PHOTO}
                    element={<CustomerPhoto />}
                  />

                  <Route path={ROUTE.HOME} element={<CustomerList />} />

                  <Route
                    path="*"
                    element={
                      <div className="flex justify-center items-center h-screen">
                        <div className="text-[184px]">404</div>
                      </div>
                    }
                  />

                  {/* LINEログインのお客様用ページ */}
                  <Route
                    path={ROUTE.CUSTOMER_MYPAGE}
                    element={<CustomerMypage />}
                  />
                  <Route
                    path={ROUTE.CUSTOMER_MYPROPOSAL}
                    element={<CustomerMyProposal />}
                  />
                  <Route
                    path={ROUTE.CUSTOMER_QUESTION_STARTER}
                    element={<CustomerQuestionStarter />}
                  />
                  <Route
                    path={ROUTE.CUSTOMER_QUESTION_SECOND}
                    element={<CustomerQuestionSecond />}
                  />
                  <Route
                    path={ROUTE.CUSTOMER_QUESTION.PRODUCT.SHOW}
                    element={<CustomerQuestionProductShow />}
                  />
                  <Route
                    path={ROUTE.CUSTOMER_QUESTION.PRE_FACE_SCAN}
                    element={<CustomerFaceScan />}
                  />
                </Routes>
              </ThemeProvider>
            </UserProvider>
          </Suspense>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
