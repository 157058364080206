import FormTreatmentCheckbox from "../FormCheckbox";

export interface ITreatment {
  id: number;
  name: string;
}

const TreatmentItem = (props: any) => {
  const checkValue = (id: number, list: ITreatment[]) => {
    if (list?.length > 0) {
      const hasId = list.some((item: ITreatment) => item.id === id);
      return hasId;
    } else {
      return false;
    }
  };

  const getNewListTreatment = () => {
    let oldTagsList = props?.listSelected;
    let check = checkValue(props?.id, oldTagsList);
    if (check) {
      const listRemoved = oldTagsList.filter(
        (item: ITreatment) => item.id !== props?.id
      );
      props?.setSubTagListSelected(listRemoved);
    } else {
      const tempTag: ITreatment = {
        id: props?.id,
        name: props?.name
      };
      oldTagsList.push(tempTag);
      props?.setSubTagListSelected(oldTagsList);
    }
  };
  return (
    <div className="w-full border-t border-[#8B8B8B] border-opacity-50">
      <FormTreatmentCheckbox
        onCheck={() => getNewListTreatment()}
        value={props?.id}
        label={props?.name}
        checked={checkValue(props?.id, props?.listSelected)}
      />
    </div>
  );
};

export default TreatmentItem;
