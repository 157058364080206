import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteApi, getApi, patchApi, postApi } from "api/common/api";
import { API_URL } from "api/common/path";
import { ROUTE } from "constants/paths";
import { NotifyTypeEnum } from "enums/notify";
import { useNavigate } from "react-router-dom";
import { IBranch } from "types/branch";
import { notify } from "utils/notify";

export const useBranchListQuery = () => {
  return useQuery(
    ["get_branches"],
    async () => {
      try {
        const { data } = await getApi(API_URL.BRANCH.INDEX, {});
        return data.branches.map((branch: IBranch) => ({
          id: branch.id,
          name: branch.name
        }));
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useAddBranch = () => {
  const navigate = useNavigate();

  return useMutation(
    (newBranch: IBranch) => {
      return postApi(API_URL.BRANCH.INDEX, newBranch);
    },
    {
      onSuccess: () => {
        notify("グループが追加されました", NotifyTypeEnum.SUCCESS);
        navigate(`${ROUTE.MYPAGE.BRANCH}`);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useBranchQuery = (branchId: string) => {
  return useQuery(
    ["get_branch", branchId],
    async () => {
      try {
        const { data } = await getApi(
          `${API_URL.BRANCH.INDEX}/${branchId}`,
          {}
        );
        return data;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useUpdateBranch = () => {
  const navigate = useNavigate();

  return useMutation(
    ({ branchId, data }: { branchId: string; data: IBranch }) => {
      return patchApi(`${API_URL.BRANCH.INDEX}/${branchId}`, data);
    },
    {
      onSuccess: () => {
        notify("グループ情報が更新されました", NotifyTypeEnum.SUCCESS);
        navigate(`${ROUTE.MYPAGE.BRANCH}`);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useDeleteBranch = () => {
  const navigate = useNavigate();

  const deleteBranch = useMutation(
    (branchId: string) => {
      return deleteApi(`${API_URL.BRANCH.INDEX}/${branchId}`, {});
    },
    {
      onSuccess: () => {
        notify("グループが削除されました", NotifyTypeEnum.SUCCESS);
        navigate(`${ROUTE.MYPAGE.BRANCH}`);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );

  return deleteBranch;
};

export const useBranchByCodeQuery = (branchCode: string) => {
  return useQuery(
    ["get_branch_by_code", branchCode],
    async () => {
      try {
        const { data } = await getApi(
          `${API_URL.CUSTOMER_BRANCH.GET_BY_CODE.replace(":code", branchCode)}`,
          {}
        );
        return data;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};
