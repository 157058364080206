interface IFormCheckbox {
  label: string;
  checked?: boolean;
  value?: string | number;
  onCheck?: () => void;
}

function FormTreatmentCheckbox(props: IFormCheckbox) {
  const id = "form-checkbox-id" + props.label + Math.random();
  return (
    <div className={"flex w-full h-full py-5 cursor-pointer block ml-8 "}>
      <input
        id={id}
        type="checkbox"
        value={props.value}
        className="inline-block h-5 rounded accent-mainColor focus:outline-none checked:accent-mainColor"
        onChange={props.onCheck}
        defaultChecked={props.checked}
      />
      <label
        htmlFor={id}
        className="text-base ml-5 cursor-pointer block w-full leading-5"
      >
        {props.label}
      </label>
    </div>
  );
}

export default FormTreatmentCheckbox;
