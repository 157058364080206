import { toast } from "react-toastify";

import {
  NotifyPositionEnum,
  NotifyThemeEnum,
  NotifyTypeEnum
} from "../enums/notify";
import { INotifyOptions } from "../types/notify";

const DEFAULT_NOTIFY_OPTIONS: INotifyOptions = {
  position: NotifyPositionEnum.TOP_RIGHT,
  type: NotifyTypeEnum.INFO,
  theme: NotifyThemeEnum.LIGHT,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  pauseOnHover: true
};

export const notify = (
  msg: string,
  type?: NotifyTypeEnum,
  options?: Partial<Omit<INotifyOptions, "type">>
) => {
  return toast(msg, {
    ...DEFAULT_NOTIFY_OPTIONS,
    ...options,
    type: type || NotifyTypeEnum.DEFAULT
  });
};
