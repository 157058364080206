import liff from "@line/liff";
import { LOCAL_STORAGE_KEY } from "constants/common";
import { useEffect, useState } from "react";
import { LineProfile } from "types/line";

const useLiff = (liffId: string) => {
  const [lineProfile, setLineProfile] = useState<LineProfile | null>(null);

  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({ liffId });
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          const profile = await liff.getProfile();
          setLineProfile(profile);
          localStorage.setItem(LOCAL_STORAGE_KEY.USER_ID, profile.userId);
        }
      } catch (err) {
        console.error("LIFF Initialization failed", err);
      }
    };

    if (liffId) {
      initializeLiff();
    }
  }, [liffId]);

  return lineProfile;
};

export default useLiff;
