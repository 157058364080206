import React from "react";
import formatNumberWithCommas from "utils/formatNumberWithCommas";

interface Product {
  id: number;
  name: string;
  price: number;
  description: string;
  sort_index: number;
  image_path: string | null;
  display_type: string;
  menu_type: string;
  tag_products: TagProduct[];
}

interface TagProduct {
  id: number;
  name: string;
}

interface DefaultMenusProps {
  defaultMenus: Product[];
}

const DefaultMenus: React.FC<DefaultMenusProps> = ({ defaultMenus }) => {
  return (
    <div>
      <h3 className="mt-8 mb-4 font-medium">基本メニュー</h3>
      {defaultMenus.map((menu) => (
        <div
          key={menu.id}
          className="flex mb-4 items-center gap-5 bg-gray-200 "
        >
          <div className="flex-none w-24 h-32">
            {menu.image_path ? (
              <img
                src={menu.image_path}
                alt={menu.name}
                className="w-24 h-32 object-cover"
              />
            ) : (
              // 画像がない場合のプレースホルダー
              <div className="bg-gray-200 w-20 h-32"></div>
            )}
          </div>
          <div className="flex-1 flex flex-col justify-between">
            <h4>{menu.name}</h4>
            <div className="flex gap-1 mt-4 justify-between">
              <p className="text-xs w-[55%]">{menu.description}</p>
              <p className="text-right pt-8 pr-4 font-medium align-bottom">
                {formatNumberWithCommas(menu.price)}円
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DefaultMenus;
