import React from "react";

type EditButtonProps = {
  label: string;
  onEditBtnClick: () => void;
  css?: string;
};

const EditButton: React.FC<EditButtonProps> = (props: EditButtonProps) => {
  // 編集ボタン押下時の処理
  const handleOnEditBtnClick = () => {
    props.onEditBtnClick();
  };

  return (
    <button
      className={`inline-block py-2 px-4 text-lg rounded focus:outline-none focus:shadow-outline ${props?.css || ""}`}
      onClick={handleOnEditBtnClick}
    >
      {props.label}
    </button>
  );
};

export default EditButton;
