import { ROUTE } from "constants/paths";
import MenuHeader from "layouts/MainLayout/MenuHeader";
import InfoTab from "pages/Customer/components/InfoTab";
import MyProposalContent from "pages/CustomerPages/components/MyProposalContent";
import ShareProposal from "pages/CustomerPages/components/ShareProposal";
import { useState } from "react";
import { useParams } from "react-router-dom";

import Container from "components/Container";

const MyProposal: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { branchCode } = useParams();

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <Container>
      <MenuHeader
        headerLabel={"マイカルテ"}
        leftLabel={"戻る"}
        leftLink={ROUTE.CUSTOMER_MYPAGE.replace(
          ":branchCode",
          branchCode || ""
        )}
      />
      <InfoTab
        onTabClick={handleTabClick}
        activeTab={activeTab}
        tab1="プレカルテ"
        tab2="共有されたカルテ"
      />
      {activeTab === 0 && <MyProposalContent />}
      {activeTab === 1 && <ShareProposal />}
    </Container>
  );
};

export default MyProposal;
