import { useBranchByCodeQuery } from "api/useBranch";
import { useCustomerQuery } from "api/useCustomer";
import { useCustomerReservationEchartQuery } from "api/useCustomerEcharts";
import { useEchartQuery } from "api/useCustomerEcharts";
import { useLineCustomerProductQuery } from "api/useCustomerTagProduct";
import { useFaceProposalImageQuery } from "api/useFaceProposalType";
import { useReservationQuery } from "api/useReservation";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ProposalButton from "components/Button/ProposalButton";
import PreReservationFaceType from "components/FaceType.tsx/PreReservationFaceType";

import CustomMenus from "./components/CustomMenus";
import DefaultMenus from "./components/DefaultMenus";
import TreatmentExampleList from "./components/TreatmentExampleList";

const MyProposalContent: React.FC = () => {
  const { branchCode } = useParams();
  const { reservationId } = useParams<{ reservationId: string }>();
  const { data: reservation } = useReservationQuery(reservationId || "1");
  const [customerId, setCustomerId] = useState<number | undefined>(undefined);
  const [currentBranchId, setCurrentBranchId] = useState<string>("");
  const [frontImageExists, setFrontImageExists] = useState<boolean>(false);
  const [echartId, setEchartId] = useState<string | undefined>(undefined);
  const [frontImg, setFrontImg] = useState<any>(undefined);
  const [faceType, setFaceType] = useState<string>("");
  const branchQuery = useBranchByCodeQuery(branchCode || "");
  const [customerName, setCustomerName] = useState<string>("");

  interface Product {
    id: number;
    name: string;
    price: number;
    description: string;
    sort_index: number;
    image_path: string | null;
    display_type: string;
    menu_type: string;
    tag_products: TagProduct[];
  }

  interface TagProduct {
    id: number;
    name: string;
  }

  useEffect(() => {
    if (reservation) {
      setCustomerId(reservation.reservation.customer_id);
    }
  }, [reservation]);

  const {
    data: customerProducts,
    isLoading,
    error
  } = useLineCustomerProductQuery(customerId, Number(currentBranchId), reservationId);

  const { data: echart } = useCustomerReservationEchartQuery(
    customerId?.toString() || "",
    reservationId || ""
  );

  useEffect(() => {
    if (echart) {
      setFrontImageExists(echart[0].image_existed);
      setEchartId(echart[0].id);
    }
  }, [echart]);

  const { data: echartData } = useEchartQuery(echartId);
  useEffect(() => {
    if (frontImageExists) {
      setFrontImg(echartData?.customer_echart?.front_image);
      setFaceType(
        echartData?.customer_echart?.front_image?.faceAnalysisResult?.faceType
      );
    }
  }, [echartData]);

  useEffect(() => {
    if (
      branchCode &&
      branchQuery &&
      !branchQuery.isLoading &&
      !branchQuery.error &&
      branchQuery.data
    ) {
      setCurrentBranchId(branchQuery.data.branch.id);
    }
  }, [branchCode, branchQuery]);

  const { data: face_type_data } = useFaceProposalImageQuery(faceType);

  // 基本メニューと商品メニューをフィルタリング
  const defaultMenus: Product[] =
    customerProducts?.filter(
      (product: Product) => product.menu_type === "default"
    ) || [];
  const customMenus: Product[] =
    customerProducts?.filter(
      (product: Product) => product.menu_type === "custom"
    ) || [];

  const { data: customerData } = useCustomerQuery(customerId?.toString() || "");

  useEffect(() => {
    if (customerData?.customer) {
      setCustomerName(
        `${customerData.customer?.last_name}${customerData.customer?.first_name}`
      );
    }
  }, [customerData]);

  const navigate = useNavigate();

  useEffect(() => {
    window.onpopstate = (e) => {
      navigate(0);
    };
  }, []);

  const handleHairCatalogClick = () => {
    if (face_type_data && face_type_data.button_url) {
      window.open(face_type_data.button_url, "_blank");
    }
  };

  return (
    <>
      <div className="w-full mt-10">
        <h2 className="text-center text-lg">
          {customerName}様のフェイスタイプ
        </h2>
        {frontImg &&
          frontImg?.faceAnalysisResult &&
          face_type_data &&
          face_type_data.image &&
          face_type_data.type &&
          faceType && (
            <PreReservationFaceType
              faceAnalysisResult={frontImg?.faceAnalysisResult}
              frontImage={frontImg?.url}
              faceTypeImage={face_type_data ? face_type_data.image : undefined}
              faceType={face_type_data ? face_type_data.type : undefined}
              faceTypeEnglish={faceType}
            />
          )}
        <ProposalButton onClick={handleHairCatalogClick}>
          ヘアカタログをもっと見る
        </ProposalButton>
        <div className="w-full mt-10">
          <h2 className="text-center text-lg">
            {customerName}様におすすめメニュー
          </h2>
          <DefaultMenus defaultMenus={defaultMenus} />
          <CustomMenus customMenus={customMenus} />
        </div>
        <div className="w-full mt-10">
          <h2 className="text-center text-lg">当店の施術例</h2>
          <TreatmentExampleList branchId={+currentBranchId} />
        </div>
      </div>
    </>
  );
};

export default MyProposalContent;
