import React, { ReactNode } from "react";

interface ContainerProps {
  children: ReactNode;
  background?: string;
}

const Container: React.FC<ContainerProps> = (props: ContainerProps) => {
  const paddingClass = props.background ? "px-4 py-4" : "p-4";
  return (
    <div
      className={`max-w-md  mx-auto md:w-auto p-4 ${paddingClass} ${
        props.background || ""
      }`}
    >
      {props.children}
    </div>
  );
};

export default Container;
