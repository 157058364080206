import { useMutation, useQuery } from "@tanstack/react-query";
import { getApi, postApi } from "api/common/api";
import { API_URL } from "api/common/path";
import { NotifyTypeEnum } from "enums/notify";
import { notify } from "utils/notify";

const API_ECHART_PATH = API_URL.CUSTOMER_ECHART.INDEX;
interface EchartCreateType {
  customer_id: string;
  reservation_id: string;
}

export const useCustomerReservationEchartQuery = (
  id: string,
  res_id: string
): any => {
  return useQuery(
    ["get_customer_reservation_echart", id, res_id],
    async () => {
      try {
        if (!(id && res_id)) {
          return false;
        }
        const get_customer_reservation_echart_api_path = `${API_ECHART_PATH}?customer_id=${id}&reservation_id=${res_id}`;
        const { data } = await getApi(
          get_customer_reservation_echart_api_path,
          {}
        );
        return data.customer_echarts || "";
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useAddEchart = () => {
  return useMutation(
    (newEchart: any) => {
      return postApi(API_ECHART_PATH, newEchart);
    },
    {
      onSuccess: (data) => {
        return data.data.customer_echart.id;
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useEchartQuery = (id: string | undefined): any => {
  return useQuery(
    ["get_echart", id],
    async () => {
      try {
        if (Number(id) > 0) {
          const get_customer_reservation_echart_api_path = `${API_ECHART_PATH}/${id}}`;
          const { data } = await getApi(
            get_customer_reservation_echart_api_path,
            {}
          );
          return data;
        }
        return false;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};
