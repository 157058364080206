import { useCustomerReservationsQuery } from "api/useReservation";
import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Container from "components/Container";
import ReservationData from "components/ReservationData";

interface ReservationData {
  id: number;
  image_path: string | null;
  with_reservation_time_range: string;
  with_menu_products: string;
  with_reservation_updated_at: string;
  with_user_names: string;
}

interface ListComponentProps {
  customerId: string;
  branchId: string;
}

const ListComponent: React.FC<ListComponentProps> = ({
  customerId,
  branchId
}) => {
  const { branchCode } = useParams();

  //API hook からデータを取る
  const { data: customerReservationsData } = useCustomerReservationsQuery(
    customerId || "0",
    branchId || "0"
  );
  if (!customerReservationsData) {
    return null;
  }

  const reservationsData: ReservationData[] =
    customerReservationsData.reservations;

  return (
    <>
      {reservationsData.map((reservation) => (
        <Link
          to={`/${branchCode}/customer-mypage/${reservation.id}/my-proposal/`}
        >
          <Container key={reservation.id} background="bg-gray-100">
            <ReservationData
              reservationDateTime={
                reservation.with_reservation_time_range || ""
              }
              reservationCreatedAt={reservation.with_reservation_updated_at}
              users={reservation.with_user_names}
              menuCategoryNames={reservation.with_menu_products}
              customerId={customerId.toString() || ""}
              reservationId={reservation.id.toString()}
            />
          </Container>
        </Link>
      ))}
    </>
  );
};

export default ListComponent;
