interface IPreReservationFaceTypeProps {
  faceAnalysisResult: any;
  frontImage: string;
  faceTypeImage: string | undefined;
  faceType: string | undefined;
  faceTypeEnglish: string;
}

const faceTypeCelebrity = [
  { faceType: "Clear", nameList: ["広瀬すず", "戸田恵梨香", "松嶋菜々子"] },
  { faceType: "Honey", nameList: ["鈴木愛理", "新垣結衣", "松本まりか"] },
  { faceType: "Sexy", nameList: ["今田美緒", "石原さとみ", "長谷川京子"] },
  { faceType: "Stylish", nameList: ["藤田ニコル", "安達祐実", "深田恭子"] },
  { faceType: "Elegant", nameList: ["目黒蓮", "岡田将生", "向井理"] },
  { faceType: "Soft", nameList: ["吉沢亮", "佐藤健", "永山瑛太"] },
  { faceType: "Dandy", nameList: ["松下洸平", "小栗旬", "竹野内豊"] },
  { faceType: "Sharp", nameList: ["横浜流星", "田中圭", "福山雅治"] }
];

const PreReservationFaceType = (props: IPreReservationFaceTypeProps) => {
  function getNameListByFaceType(
    data: { faceType: string; nameList: string[] }[],
    faceType: string
  ) {
    const result = data.filter((item) => item.faceType === faceType);
    const nameList = result[0].nameList || [];
    return (
      <>
        {nameList.map((name: string, id: number) => (
          <span key={id} className="block">
            {name}
          </span>
        ))}
      </>
    );
  }

  return (
    <div className="pt-[52px] pb-[16px] bg-white">
      {props?.faceAnalysisResult &&
      props?.faceAnalysisResult?.checkedMouthFeature ? (
        <div className="mt-6 h-full w-full px-4">
          {props.faceTypeImage && (
            <div className="flex bg-gray-100 p-4 mb-4">
              <div className="w-1/2">
                <p>{props.faceTypeEnglish} Type</p>
                <p>{props.faceType}</p>
                <div className="whitespace-pre-wrap">
                  {getNameListByFaceType(
                    faceTypeCelebrity,
                    props.faceTypeEnglish
                  )}
                </div>
              </div>
              <div className="w-1/2">
                <img
                  src={props.faceTypeImage}
                  alt="face_type_image"
                  className="mx-auto"
                />
              </div>
            </div>
          )}
          {props?.faceAnalysisResult?.faceType &&
            props?.faceAnalysisResult?.faceTypeDesc}
        </div>
      ) : (
        <div className="text-center text-sm text-gray-500 mt-6">データなし</div>
      )}
    </div>
  );
};

export default PreReservationFaceType;
