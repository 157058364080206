import { Link, useNavigate } from "react-router-dom";
import { MenuHeaderProps } from "types/common";

const MenuHeader: React.FC<MenuHeaderProps> = ({
  headerLabel,
  leftLabel = "戻る",
  leftLink,
  leftComponent,
  rightComponent,
  rightComponentCss
}) => {
  // leftLinkかleftComponentのどちらかをレンダリングする
  const renderLeftLink = () => {
    if (!leftComponent && leftLink) {
      if (typeof leftLink === "function") {
        return (
          <button onClick={leftLink} className="text-lg">
            {leftLabel}
          </button>
        );
      } else {
        return (
          <Link to={leftLink} className="text-lg">
            {leftLabel}
          </Link>
        );
      }
    }
    return null;
  };

  return (
    <div className="flex w-full justify-between items-center whitespace-nowrap">
      <div className="w-full py-2 px-4">
        {leftComponent || renderLeftLink()}
      </div>
      <div className="text-center w-full inline-block py-2 px-4 text-lg rounded focus:outline-none focus:shadow-outline whitespace-nowrap">
        {headerLabel}
      </div>
      <div
        className={`text-center w-full inline-block py-2 px-4 text-lg rounded focus:outline-none focus:shadow-outline ${rightComponentCss || ""}`}
      >
        {rightComponent}
      </div>
    </div>
  );
};

export default MenuHeader;
