import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  refetchQuery
} from "api/common/api";
import { API_URL } from "api/common/path";
import { ROUTE } from "constants/paths";
import { NotifyTypeEnum } from "enums/notify";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import { useNavigate } from "react-router-dom";
import { ITreatmentExample } from "types/treatmentExample";
import { notify } from "utils/notify";

export interface UseTreatmentExampleListVariables {
  reservationId?: string;
}

export const useTreatmentExampleListQuery = (
  branchId?: number,
  variables?: UseTreatmentExampleListVariables
) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["get_treatmentExample", branchId, variables, refetchQuery],
    async () => {
      try {
        const apiUrl = branchId
          ? API_URL.CUSTOMER_TREATMENT_EXAMPLE.INDEX
          : variables?.reservationId
            ? API_URL.TREATMENT_EXAMPLE.RESERVATION
            : API_URL.TREATMENT_EXAMPLE.INDEX;

        const params = omitBy(
          { branch_id: branchId, reservation_id: variables?.reservationId },
          isNil
        );

        const { data } = await getApi(apiUrl, params);

        return data.treatment_examples.map(
          (treatmentExample: ITreatmentExample) => ({
            id: treatmentExample.id,
            name: treatmentExample.name,
            display_type: treatmentExample.display_type,
            image_path: treatmentExample.image_path
          })
        );
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export interface UseCustomerTreatmentExampleListVariables {
  reservationId?: string;
}

// Lineカスタマー用
export const useCustomerTreatmentExampleListQuery = (
  branchId: number,
  variables?: UseCustomerTreatmentExampleListVariables
) => {
  return useQuery(
    ["get_customer_treatmentExample", branchId, variables],
    async () => {
      try {
        const params = omitBy(
          { branch_id: branchId, reservation_id: variables?.reservationId },
          isNil
        );
        const { data } = await getApi(
          API_URL.CUSTOMER_TREATMENT_EXAMPLE.INDEX,
          params
        );
        return data.treatment_examples.map(
          (treatmentExample: ITreatmentExample) => ({
            id: treatmentExample.id,
            name: treatmentExample.name,
            display_type: treatmentExample.display_type,
            image_path: treatmentExample.image_path
          })
        );
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useAddTreatmentExample = () => {
  const navigate = useNavigate();

  return useMutation(
    (newTreatmentExample: ITreatmentExample) => {
      return postApi(API_URL.TREATMENT_EXAMPLE.INDEX, newTreatmentExample);
    },
    {
      onSuccess: () => {
        notify("施術例が追加されました", NotifyTypeEnum.SUCCESS);
        navigate(`${ROUTE.MYPAGE.TREATMENT_EXAMPLE}`);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useTreatmentExampleQuery = (treatmentExampleId: string) => {
  return useQuery(
    ["get_treatment_example", treatmentExampleId],
    async () => {
      try {
        const { data } = await getApi(
          `${API_URL.TREATMENT_EXAMPLE.INDEX}/${treatmentExampleId}`,
          {}
        );
        return data;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useUpdateTreatmentExample = () => {
  const navigate = useNavigate();

  return useMutation(
    ({
      treatmentExampleId,
      data
    }: {
      treatmentExampleId: string;
      data: ITreatmentExample;
    }) => {
      return patchApi(
        `${API_URL.TREATMENT_EXAMPLE.INDEX}/${treatmentExampleId}`,
        data
      );
    },
    {
      onSuccess: () => {
        notify("施術例が更新されました", NotifyTypeEnum.SUCCESS);
        navigate(`${ROUTE.MYPAGE.TREATMENT_EXAMPLE}`);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

export const useDeleteTreatmentExample = () => {
  const navigate = useNavigate();

  const deleteBranch = useMutation(
    (treatmentExampleId: string) => {
      return deleteApi(
        `${API_URL.TREATMENT_EXAMPLE.INDEX}/${treatmentExampleId}`,
        {}
      );
    },
    {
      onSuccess: () => {
        notify("施術例が削除されました", NotifyTypeEnum.SUCCESS);
        navigate(`${ROUTE.MYPAGE.TREATMENT_EXAMPLE}`);
      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );

  return deleteBranch;
};
