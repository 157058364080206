import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getApi } from "api/common/api";
import { API_URL } from "api/common/path";
import { NotifyTypeEnum } from "enums/notify";
import { notify } from "utils/notify";

export const useCustomerProductQuery = (customer_id: number | undefined) => {
  return useQuery(["get_customer_tag_products", customer_id], async () => {
    try {
      const { data } = await getApi(
        `${API_URL.CUSTOMER_TAG_PRODUCTS.INDEX}?customer_id=${customer_id}`,
        {}
      );
      return data?.customer_tag_products;
    } catch (error: any) {
      throw error;
    }
  });
};

export const useLineCustomerProductQuery = (
  customer_id: number | undefined,
  branch_id: number | undefined,
  reservation_id?: number | string | undefined
) => {
  return useQuery(
    ["get_line_customer_tag_products", customer_id || "0", branch_id || "0"],
    async () => {
      if (!customer_id || !branch_id) return null;
      try {
        const { data } = await getApi(
          `${API_URL.LINE_CUSTOMER_TAG_PRODUCTS.INDEX}?customer_id=${customer_id}&branch_id=${branch_id}&reservation_id=${reservation_id}`,
          {}
        );
        return data?.customer_tag_products;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};
