import SellIcon from "@mui/icons-material/SellOutlined";

interface IEditLinkedTopTextProps {
  label: string;
  description: string;
}
const EditLinkedTopText: React.FC<IEditLinkedTopTextProps> = (
  props: IEditLinkedTopTextProps
) => {
  return (
    <div className="text-left ml-5 mt-9">
      <SellIcon className="text-mainColor" />
      <span className="text-mainColor">{props.label}</span>
      {props.description}
    </div>
  );
};
export default EditLinkedTopText;
