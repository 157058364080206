import { QueryClient, QueryKey } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { CAMERA, LOCAL_STORAGE_KEY } from "../../constants/common";
import { ROUTE } from "../../constants/paths";
import { BASE_API_URL, INIT_ENV } from "../../environments";
import { getToken, getUserId } from "../../utils/common";

export const api = axios.create({
  baseURL:
    BASE_API_URL[INIT_ENV as keyof typeof BASE_API_URL] || BASE_API_URL.PROD,
  // timeout: 25000,
  timeout: 86400000,

  headers: {
    "X-Requested-With": "XMLHttpRequest"
  }
});

api.interceptors.request.use(
  (config) => {
    config.headers = config.headers ?? {};

    config.headers["Authorization"] = `Bearer ${getToken()}`;
    if (getUserId()) {
      config.headers["User-Id"] = `${getUserId()}`;
    }
    return config;
  },

  (err: AxiosError) => Promise.reject(err)
);

api.interceptors.response.use(
  (res) => Promise.resolve(res),
  (error) => {
    if (
      error.response.status === 401 &&
      !window.location.pathname.includes("login")
    ) {
      localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEY.USER_INFO);
      localStorage.removeItem(LOCAL_STORAGE_KEY.COMPANY_INFO);
      localStorage.removeItem(CAMERA.FACING_MODE);

      window.location.href = window.location.origin + ROUTE.LOGIN;
    }
    if (error.response.status === 404) {
      window.location.href = ROUTE.NOT_FOUND;
    }
    if (error.response.status === 402) {
      // window.location.href = window.location.origin + ROUTE.CUSTOMER_MYPAGE;
    }
    return Promise.reject(error);
  }
);

export const getApi = (url = "", params: any) =>
  api.get(url, { params }).then((res) => res);

export const postApi = <T = any>(url = "", params: any, queryParams?: any) =>
  api.post<T>(url, params, { params: queryParams }).then((res) => res);

export const putApi = (url = "", params: any) =>
  api.put(url, params).then((res) => res);

export const patchApi = (url = "", params: any) =>
  api.patch(url, params).then((res) => res);

export const deleteApi = (url = "", params: any) =>
  api.delete(url, { data: params }).then((res) => res);

export const refetchQuery = (queryClient: QueryClient, keysArr: QueryKey) => {
  queryClient.invalidateQueries({
    queryKey: keysArr
  });
  queryClient.refetchQueries({
    queryKey: keysArr
  });
};
