import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCustomerTreatmentExampleListQuery } from "api/useTreatmentExample";
import { useState } from "react";
import { useParams } from "react-router";
import { ITreatmentExample } from "types/treatmentExample";

interface TreatmentExampleListProps {
  branchId: number;
}

const TreatmentExampleList: React.FC<TreatmentExampleListProps> = ({
  branchId
}) => {
  const params = useParams();
  const reservationId = params?.reservationId;

  const {
    data: treatmentExampleList,
    refetch: refetchTreatmentExamples,
    isLoading
  } = useCustomerTreatmentExampleListQuery(branchId, { reservationId });

  const [loadIndex, setLoadIndex] = useState(4);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOnDisplayMoreBtn = () => {
    if (!isExpanded) {
      setLoadIndex(treatmentExampleList.length);
    }

    setIsExpanded(!isExpanded);
  };

  const itemHeight = 210;
  const listStyle = {
    maxHeight: isExpanded
      ? `${itemHeight * treatmentExampleList.length}px`
      : "420px",
    overflow: "hidden",
    transition: "max-height 0.2s ease-in-out"
  };

  return (
    <div className="mt-10 border rounded py-3">
      <div className="grid grid-cols-2 gap-1" style={listStyle}>
        {treatmentExampleList &&
          treatmentExampleList
            .slice(0, loadIndex)
            .map((example: ITreatmentExample) => (
              <div
                key={example.id}
                className="px-2 py-[5px] flex justify-center items-center"
              >
                {example.image_path && (
                  <img
                    src={example.image_path}
                    alt={example.name}
                    className="w-auto h-[200px] object-cover"
                  />
                )}
              </div>
            ))}
      </div>
      {treatmentExampleList && treatmentExampleList.length > 4 && (
        <div className="flex justify-center items-center mt-8 mb-5">
          <button onClick={handleOnDisplayMoreBtn}>
            {isExpanded ? "閉じる" : "もっと見る"}
            <ExpandMoreIcon
              style={{
                marginLeft: "5px",
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.2s ease-in-out"
              }}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default TreatmentExampleList;
