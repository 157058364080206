import { LOCAL_STORAGE_KEY } from "constants/common";
import React, { createContext, useContext, useEffect, useState } from "react";
import { UserContextType, UserInfo, UserProviderProps } from "types/user";

const UserContext = createContext<UserContextType | null>(null);

export const UserProvider = ({ children }: UserProviderProps) => {
  // ローカルストレージから初期値を取得
  const [userInfo, setUserInfo] = useState<UserInfo | null>(() => {
    const savedUserInfo = localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO);
    return savedUserInfo ? JSON.parse(savedUserInfo) : null;
  });

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    const setUserInfo = () => {
      console.warn("UserContext not found");
    };
    return { userInfo: null, setUserInfo };
  }
  return context;
};
