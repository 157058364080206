interface ProposalButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

const ProposalButton: React.FC<ProposalButtonProps> = ({
  onClick,
  children
}) => {
  return (
    <div className="flex justify-center items-center mt-10">
      <button
        onClick={onClick}
        className="bg-mainColor w-[253px] h-[54px] text-white py-2 px-4 rounded-full shadow-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-300"
      >
        {children}
      </button>
    </div>
  );
};

export default ProposalButton;
