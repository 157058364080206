import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'context/UserContext';
import { UserInfo } from 'types/user';

const useAuthorization = (roles: string[], redirectRoute: string) => {
  const navigate = useNavigate();
  const { userInfo } = useUser();

  useEffect(() => {
    const hasPermission = (userInfo: UserInfo | null, roles: string[]) => {
      if (!userInfo) return false;
      return roles.includes(userInfo.role);
    };

    if (!hasPermission(userInfo, roles)) {
      navigate(redirectRoute);
    }
  }, [navigate, userInfo, roles, redirectRoute]);
};

export default useAuthorization;
