enum NotifyPositionEnum {
  TOP_LEFT = "top-left",
  TOP_RIGHT = "top-right",
  TOP_CENTER = "top-center",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_RIGHT = "bottom-right",
  BOTTOM_CENTER = "bottom-center"
}
enum NotifyTypeEnum {
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  DEFAULT = "default"
}
enum NotifyThemeEnum {
  LIGHT = "light",
  DARK = "dark",
  COLORED = "colored"
}

export { NotifyPositionEnum, NotifyTypeEnum, NotifyThemeEnum };
