import {
  useCustomerReservationEchartQuery,
  useEchartQuery
} from "api/useCustomerEcharts";
import { ReactComponent as CustomerIconThumbnail } from "assets/icons/ic_round-face-thumbnail.svg";
import React, { useEffect, useState } from "react";

interface ReservationDataProps {
  reservationDateTime: string;
  reservationCreatedAt: string;
  users: string;
  menuCategoryNames: string;
  customerId: string;
  reservationId: string;
}

const ReservationData: React.FC<ReservationDataProps> = (props) => {
  const [echartId, setEchartId] = useState<number>(0);
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

  const { data: echart } = useCustomerReservationEchartQuery(
    props.customerId,
    props.reservationId
  );

  useEffect(() => {
    if (echart && echart[0].image_existed) {
      setEchartId(echart[0].id);
    }
  }, [echart]);

  const { data: echartPhoto } = useEchartQuery(echartId.toString());

  useEffect(() => {
    const customerEchart = echartPhoto?.customer_echart;

    if (echartPhoto) {
      const imageUrl = customerEchart.after_image_existed
        ? customerEchart.after_front_image.url
        : customerEchart.front_image.url;
      setImageSrc(imageUrl);
    }
  }, [echartPhoto]);

  return (
    <div className="bg-white w-full flex items-center justify-start py-4">
      <div className="mx-4">
        {imageSrc ? (
          <img
            src={imageSrc}
            alt="Uploaded"
            className="w-20 h-20 object-cover object-center"
            style={{ minWidth: "120px", minHeight: "120px" }}
          />
        ) : (
          <div
            className="flex items-center justify-center w-20 h-20 bg-gray-300"
            style={{ minWidth: "120px", minHeight: "120px" }}
          >
            <CustomerIconThumbnail className="w-16 h-auto" />
          </div>
        )}
      </div>
      <div>
        <div className="mb-2">{`作成日 : ${props.reservationCreatedAt}`}</div>
        <div className="mb-2">{`来店日 : ${
          props.reservationDateTime || ""
        }`}</div>
        <div className="mb-2">{`メニュー : ${props.menuCategoryNames}`}</div>
        <div className="mb-2">{`担当者 : ${props.users}`}</div>
      </div>
    </div>
  );
};

export default ReservationData;
