import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getApi, patchApi, refetchQuery } from "api/common/api";
import { API_URL } from "api/common/path";
import { NotifyTypeEnum } from "enums/notify";

import { notify } from "./notify";
import { useNavigate } from "react-router";

export const useGetTagQuery = (tagId: string) => {
  return useQuery(
    ["tagProduct", tagId],
    () => {
      return getApi(
        API_URL.TAGS.TAG_PRODUCT_GET.replace(":id", tagId),
        {}
      ).then((response: any) => response.data.tag_product);
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useUpdateTagTreatmentListQuery = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      tagId,
      data
    }: {
      tagId: string;
      data: any;
    }) => {
      return patchApi(
        API_URL.TAGS.TAG_PRODUCT_GET.replace(":id", tagId),
        data
      );
    },
    {
      onSuccess: () => {
        refetchQuery(queryClient, ["tagProduct"]);
        setTimeout(() => navigate(-1), 500);

      },
      onError: (error: any) => {
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR);
      }
    }
  );
};

