import { useTreatmentExampleListQuery } from "api/useTreatmentExample";
import { ROUTE } from "constants/paths";
import { NotifyTypeEnum } from "enums/notify";
import useAuthorization from "hocs/useAuthorization";
import MenuHeader from "layouts/MainLayout/MenuHeader";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TagProduct } from "types/question-product";
import { useGetTagQuery, useUpdateTagTreatmentListQuery } from "utils/tag";

import EditButton from "components/Button/EditButton";
import Container from "components/Container";
import Loading from "components/Loading";

import EditLinkedTopText from "../components/EditLinkedTopText";
import TreatmentListItems from "../components/TreatmentListItem";

const TreatmentTagList: React.FC = () => {
  useAuthorization(["system_admin", "admin", "owner"], ROUTE.MYPAGE.INDEX);
  const navigate = useNavigate();
  const [subTagListSelected, setSubTagListSelected] = useState<TagProduct[]>(
    []
  );
  const params = useParams<{ tagId: string }>();
  const { data: treatmentExampleList } = useTreatmentExampleListQuery();
  const { data: tagProduct, isLoading } = useGetTagQuery(params.tagId || "");

  const { mutate: updateTagTreatmentListQuery } =
    useUpdateTagTreatmentListQuery();
  const getListTreatmentIds = (listTreatment: any[]) => {
    let listIds: number[];
    listIds = listTreatment.map((item) => item.id);
    return listIds;
  };
  useEffect(() => {
    if (tagProduct?.treatment_examples?.length > 0) {
      const newList = tagProduct?.treatment_examples.map((item: any) => ({
        id: item.id,
        name: item.name
      }));
      setSubTagListSelected(newList);
    }
  }, [tagProduct?.treatment_examples]);

  const saveListTreatment = () => {
    const listId = getListTreatmentIds(subTagListSelected);
    updateTagTreatmentListQuery({
      tagId: params.tagId || "",
      data: {
        treatment_example_ids: listId
      }
    });
  };

  return (
    <Container>
      <MenuHeader
        headerLabel={"施術例を選択"}
        leftLabel={"戻る"}
        leftLink={() => {
          navigate(-1);
        }}
        rightComponent={
          <EditButton
            label="保存"
            css="!px-0"
            onEditBtnClick={() => {
              saveListTreatment();
            }}
          />
        }
        rightComponentCss="!text-right !px-0"
      />
      <EditLinkedTopText
        label={tagProduct?.name || ""}
        description="とリンクさせる施術例を選択してください"
      />
      {isLoading ? (
        <Loading />
      ) : (
        <TreatmentListItems
          subTagListSelected={subTagListSelected}
          setSubTagListSelected={setSubTagListSelected}
          tagProduct={tagProduct}
          treatmentExampleList={treatmentExampleList}
        />
      )}
    </Container>
  );
};

export default TreatmentTagList;
