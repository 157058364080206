import { useMutation, useQuery } from "@tanstack/react-query";
import { NotifyTypeEnum } from "enums/notify";
import { notify } from "utils/notify";

import { getApi, postApi } from "./common/api";
import { API_URL } from "./common/path";

export const useReservationLatestQuery = (
  customerId: string,
  branchId: string,
  createdAt?: string
) => {
  const per = 1;
  const page = 1;
  const API_GET_PATH = createdAt
    ? `${API_URL.RESERVATION.GET}?page=${page}&per=${per}&created_at=${createdAt}&customer_id=${customerId}&branch_id=${branchId}`
    : `${API_URL.RESERVATION.GET}?page=${page}&per=${per}&customer_id=${customerId}&branch_id=${branchId}`;
  return useQuery(
    ["get_latest_reservation", customerId, branchId],
    async () => {
      try {
        const { data } = await getApi(API_GET_PATH, {});
        return data;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useCustomerReservationsQuery = (
  customerId: string,
  branchId: string
) => {
  let API_GET_PATH = `${API_URL.RESERVATION.GET}?&customer_id=${customerId}`;
  if (branchId) {
    API_GET_PATH += `&branch_id=${branchId}`;
  }
  return useQuery(
    ["get_all_customer_reservations", customerId, branchId],
    async () => {
      try {
        const { data } = await getApi(API_GET_PATH, {});
        return data;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useReservationQuery = (res_id: string) => {
  const API_GET_PATH = `${API_URL.RESERVATION.GET}/${res_id}`;
  return useQuery(
    ["get_reservation", res_id],
    async () => {
      try {
        const { data } = await getApi(API_GET_PATH, {});
        return data;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useAfterReservationFollowAddMutation = () => {
  return useMutation(
    async (data: any) => {
      try {
        const { data: res } = await postApi(
          API_URL.RESERVATION.AFTER_FOLLOW_ADD,
          data
        );
        return res;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        notify("アフターフォロー情報を登録しました。", NotifyTypeEnum.SUCCESS);
      },
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useAddReservation = () => {
  return useMutation(
    async (reservation: any) => {
      try {
        const { data } = await postApi(API_URL.RESERVATION.ADD, reservation);
        return data;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        notify("カルテが新規作成されました", NotifyTypeEnum.SUCCESS);
      },
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};

export const useCreateByLatestReservation = () => {
  return useMutation(
    async (param: any) => {
      try {
        const { data } = await postApi(
          API_URL.RESERVATION.CREATE_BY_LATEST,
          param
        );
        return data.reservation;
      } catch (error: any) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        notify("カルテが新規作成されました", NotifyTypeEnum.SUCCESS);
      },
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};
