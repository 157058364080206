import React, { ComponentType } from "react";
import { Navigate } from "react-router-dom";

import { ROUTE } from "../constants/paths";
import { getToken, isLoggedIn } from "../utils/common";

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const AuthenticatedComponent: React.FC<P> = (props) => {
    const isAuthenticated = isLoggedIn();
    if (isAuthenticated) {
      return <WrappedComponent {...props} />;
    }
    return <Navigate to={ROUTE.LOGIN} />;
  };

  return AuthenticatedComponent;
};

export default withAuth;
