import { ConstructionOutlined } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "api/common/api";
import { API_URL } from "api/common/path";
import { NotifyTypeEnum } from "enums/notify";
import { notify } from "utils/notify";

export const useFaceProposalImageQuery = (code: string): any => {
  return useQuery(
    ["get_face_proposal_image", code],
    async () => {
      try {
        if (code === "") {
          return false;
        }
        const url = `${API_URL.FACE_PROPOSAL.GET}?code=${code}`;
        const { data } = await getApi(url, {});
        return {
          type: data.face_proposal_types[0]?.face_type,
          image: data.face_proposal_types[0]?.image_url,
          button_url: data.face_proposal_types[0]?.button_url
        };
      } catch (error: any) {
        throw error;
      }
    },
    {
      onError: (error: any) =>
        notify(error?.response?.data?.error, NotifyTypeEnum.ERROR)
    }
  );
};
