import TreatmentItem from "../TreatmentItem";

const TreatmentListItems = (props: any) => {
  return (
    <div className={`mt-8`}>
      {props?.treatmentExampleList?.length > 0 &&
        props?.treatmentExampleList?.map((item: any) => (
          <TreatmentItem
            key={item.id}
            id={item?.id}
            name={item?.name}
            listSelected={props?.subTagListSelected}
            setSubTagListSelected={props?.setSubTagListSelected}
          />
        ))}
    </div>
  );
};

export default TreatmentListItems;
