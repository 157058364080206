import { useState } from "react";

interface Images {
  id: number;
  url: string;
}

interface IFaceTypeProposalProps {
  faceAnalysisResult: any;
  frontImage: string;
  imgList: Images[];
}

const FaceTypeProposal = (props: IFaceTypeProposalProps) => {
  const [mainImgSrc, setMainImgSrc] = useState<string>(props.frontImage);

  const handleImageClick = (url: string) => {
    setMainImgSrc(url);
  };

  return (
    <div className="pb-[16px] bg-white">
      {props?.faceAnalysisResult &&
      props?.faceAnalysisResult?.checkedMouthFeature ? (
        <div className="mt-6 h w-full">
          {props.frontImage && (
            <div className="mb-4">
              <div className="w-full">
                <img
                  src={mainImgSrc}
                  alt="face_type_image"
                  className="mx-auto"
                />
              </div>
              <div className="flex flex-wrap">
                {props.imgList.map((image, index) => (
                  <div key={index} className="mt-4 mr-2 flex-shrink-0">
                    <img
                      src={image.url}
                      alt={`face_type_image_${index}`}
                      className="w-16 h-16 object-cover"
                      onClick={() => handleImageClick(image.url)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center text-sm text-gray-500 mt-6">データなし</div>
      )}
    </div>
  );
};

export default FaceTypeProposal;
